import { ProduitData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../../../components/View';
import Form from '../../../../../../containers/Espace/Form';
import params from '../../../../../../params/produit/disponibilites.json';
import TemplateEspace from '../../../../../../templates/espace';
import requireEspace, {
  EspaceProps,
} from '../../../../../../utils/requireEspace';
import { UserProps } from '../../../../../../utils/requireUser';

const ProduitsDisponibilites: FC<PageProps & EspaceProps & UserProps> = ({
  location,
  espace,
  user,
  params: { espaceId, produitId },
}) => (
  <TemplateEspace espace={espace} user={user}>
    <CMSView>
      <Form
        displayAdd={false}
        displayNavigation={false}
        displayRemove={false}
        docId={produitId}
        itemPathnamePrefix={location.pathname}
        model={
          new ProduitData({
            espaceId,
            params,
          })
        }
        name="event"
        params={{ produitId }}
        title="Disponibilités"
        type="update"
      />
    </CMSView>
  </TemplateEspace>
);

export default requireEspace(ProduitsDisponibilites);
